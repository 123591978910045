import React from 'react';
import { observer } from 'mobx-react';

import { Field } from '~/util/formz/Components/fields/Field';
import { SearchByQuoteNumberFormStore } from '~/account/management/Views/quotes/Stores/SearchByQuoteNumber.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';

const QuoteNumberLookup = observer((props: { store: SearchByQuoteNumberFormStore}) => {
	const { featureTogglesModel } = useGlobalContext();
	const submitText = featureTogglesModel.isOn('ENGAGE_TOOLBAR_REDESIGN') ? 'Search' : 'Submit';
	const {
		store: {
			form: {
				reactProps,
				fields: {
					quoteNumber: quoteNumberField
				}
			}
		}
	} = props;

	const {
		key,
		...formReactProps
	} = reactProps;

	return (
		<form key={key} {...formReactProps}>
			<div className="form-layout">
				<Field field={quoteNumberField} afterLabel={<span className="after-label">Minimum 8 characters</span>} />
			</div>
			<input type="submit" className="ButtonTertiary" value={submitText} data-qa="quote-search-button" />
		</form>

	);
});

export { QuoteNumberLookup };
