import React from 'react';
import { action } from 'mobx';

import { CreateAccountStore } from '~/engage/toolbar/create-account/Stores/CreateAccount.store';
import { CreateAccount } from '~/engage/toolbar/create-account/Components/CreateAccount';

export const createAccountHandler = action((magicModal, globalDynamicStore, options) => {
	const createAccountStore = new CreateAccountStore(magicModal, globalDynamicStore, options);
	const modalOptions = options?.modalOptions || {};

	magicModal.openModal({
		id: 'create-account',
		title: 'Set Up Online Access',
		maxWidth: '410px',
		content: {
			children: <CreateAccount createAccountStore={createAccountStore} />,
		},
		...modalOptions,
	});
});
