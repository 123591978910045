import React from 'react';
import { observer } from 'mobx-react';

import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

import styles from '~/engage/toolbar/customer-side-menu/Components/customers-side-menu.legacy.module.scss';

export const RecentCustomers = observer(({ store = {} }) => {
	const {
		isLoading,
		customers = [],
		seeMoreRecentCustomers,
		proxyRecentCustomer,
		recentMoreCustomersLink,
	} = store;

	if (isLoading) {
		return (
			<div>
				<LoadingSpinner minHeight={168} isLoading={isLoading} />
			</div>
		);
	}

	return (
		<div>
			<ol className={styles['recent-customers-list']}>
				{
					customers.map((item, i) => {
						const activityDate = new Intl.DateTimeFormat('en-US', {
							month: '2-digit',
							day: '2-digit'
						}).format(new Date(item.lastActivityDate));

						return (
							<li key={`recent-customers-list-item-${i}`} className={styles['customers-side-menu-list-item']}>
								<button
									className={`${styles['recent-customers-button']} button-as-anchor`}
									data-track="Recent Customer Selected"
									data-tr-link-event-type={LinkEventTypes.INTERNAL}
									onClick={(event) => {
										const link = item.proxyLink;

										event.preventDefault();
										if (link) {
											proxyRecentCustomer(link);
										}
									}}
								>
									<span className={styles['recent-customers-entry']} data-qa="recent-customers-entry">{item.name} </span>
									<span className={`${styles['recent-customers-entry']} ${styles['recent-customers-last-activity-date']}`} data-qa="recent-customers-entry">
										{activityDate}
									</span>
								</button>
							</li>
						);
					})
				}
				{
					recentMoreCustomersLink &&
					<li key="see-more-recent-customers" className={styles['customers-side-menu-list-item']}>
						<button
							type="button"
							className={`${styles['recent-customers-button']} button-as-anchor`}
							onClick={seeMoreRecentCustomers}
							data-qa="see-more-recent-customers-button"
						>
							<span className={styles['recent-customers-entry']} data-qa="recent-customers-entry">See more recent customers »</span>
						</button>
					</li>
				}
			</ol>
		</div>
	);
});
