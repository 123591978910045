import React from 'react';
import { observer } from 'mobx-react';

import { CustomerLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/CustomerLookup';
import { RecentCustomers } from '~/engage/toolbar/customer-side-menu/recent-customers/Components/RecentCustomers';
import { Modal } from '~/engage/recent-customers/Components/Modal';
import { AvailableDeliveryDates } from '~/engage/available-delivery-dates/Components/AvailableDeliveryDates';
import { AvailableDeliveryDatesStore } from '~/engage/available-delivery-dates/Stores/AvailableDeliveryDates.store';
import { CartSearch } from '~/engage/cart-search/Components/CartSearch';
import { MagicOverlay } from '~/components/magic-overlay/Components/MagicOverlay';
import { useToolbarStores } from '~/engage/toolbar/Contexts/Toolbar.context';
import { useGlobalContext } from '~/global/Contexts/Global.context';

import legacyStyles from '~/engage/toolbar/side-menu/Components/side-menu.legacy.module.scss';
import styles from '~/engage/toolbar/side-menu/Components/side-menu.module.scss';
import legacyCustomerStyles from '~/engage/toolbar/customer-side-menu/Components/customers-side-menu.legacy.module.scss';
import customerStyles from '~/engage/toolbar/customer-side-menu/Components/customers-side-menu.module.scss';
import recentCustomerStyles from '~/engage/toolbar/customer-side-menu/Components/recent-customers-modal.module.scss';

// @deprecated ENGAGE_TOOLBAR_REDESIGN
export const CustomerSideMenuLegacy = observer((props) => {
	const { toolbarStore } = useToolbarStores();
	const { globalDynamicStore, magicModal } = useGlobalContext();
	const {
		menuStore: {
			deliveryAvailabilityLink,
			hasErrors = false,
			lookupStore,
			overlay,
			recentCustomersStore,
		} = {},
	} = props;
	const deliveryDatesHandler = () => {
		const availDelDatesStore = new AvailableDeliveryDatesStore(deliveryAvailabilityLink, globalDynamicStore);

		availDelDatesStore.init();
		magicModal.openModal({
			id: 'available-delivery-dates-modal',
			title: 'Available Delivery Dates',
			maxWidth: 372,
			showCloseButton: true,
			content: {
				children: <AvailableDeliveryDates store={availDelDatesStore} />,
			}
		});
	};
	const todaysCustomersHandler = () => {
		toolbarStore.closeMenu();
		const modalProps = {
			timeFormat: 'h:mm a',
			link: props.menuStore.customersToolbarResults?._links?.recentCustomersByLocationPages?.href,
		};

		magicModal.openModal({
			containerClass: recentCustomerStyles['most-recent-customers-modal'],
			id: 'most-recent-customers-modal',
			title: 'Today’s Customers',
			maxWidth: 520,
			content: {
				children: <Modal {...modalProps} />,
			},
		});
	};
	// const myOpportunitiesHandler = () => {
	// 	const { listMyOpportunitiesLink } = globalStaticModel;
	//
	// 	magicModal.openModal({
	// 		title: 'My Opportunities',
	// 		maxWidth: 600,
	// 		showCloseButton: true,
	// 		content: {
	// 			children: <Opportunities store={
	// 				new OpportunitiesStore(
	// 					listMyOpportunitiesLink,
	// 					globalStaticModel,
	// 					globalDynamicStore,
	// 					magicModal,
	// 					featureTogglesModel,
	// 					HREF,
	// 				)} />,
	// 		},
	// 	});
	// };

	return (
		<div>
			<MagicOverlay store={overlay} containerSelector=".oe-side-menu" />
			{
				hasErrors &&
				<div className="tw-text-red tw-mt-4">
					An unknown error occurred. If the problem persists, please open a Help Desk ticket.
				</div>
			}
			{
				Boolean(lookupStore) && Boolean(recentCustomersStore) && !hasErrors &&
				<div className={legacyStyles['side-menu-contents']} data-qa="side-menu-contents">
					<section className={legacyStyles.section}>
						<h3
							className="tw-heading-6 tw-uppercase"
							data-qa="search-customer-title"
						>
							Search for a customer
						</h3>
						<CustomerLookup store={lookupStore} />
					</section>
					<section className={legacyStyles.section}>
						<h3 className="tw-heading-6 tw-uppercase">My recent customers</h3>
						<RecentCustomers store={recentCustomersStore} />
					</section>
					<section className={legacyStyles.section}>
						<h3 className="tw-heading-6 tw-uppercase">More Tools</h3>
						<ul className={legacyCustomerStyles['customers-side-menu-list']}>
							<li className={legacyCustomerStyles['customers-side-menu-list-item']}>
								<button
									className="customers-side-menu-button-text button-as-anchor"
									type="button"
									onClick={todaysCustomersHandler}
								>Today’s customers in my location</button>
							</li>
							<li className={legacyCustomerStyles['customers-side-menu-list-item']}>
								<a
									className="customers-side-menu-button-text button-as-anchor"
									data-qa="my-opportunities-link"
									href="/opportunities"
								>
									My opportunities
								</a>
							</li>
							{
								Boolean(deliveryAvailabilityLink) &&
								<li className={legacyCustomerStyles['customers-side-menu-list-item']}>
									<button
										className="customers-side-menu-button-text button-as-anchor"
										data-qa="delivery-dates-link"
										onClick={deliveryDatesHandler}
									>
										Available delivery dates by <span className="tw-uppercase">zip</span>
									</button>
								</li>
							}
						</ul>
					</section>
					{
						Boolean(globalDynamicStore.model.proxyCustomerByCartNumberLink) &&
						<section className={legacyStyles.section}>
							<h3 className="tw-heading-6 tw-uppercase">
								<label htmlFor="cartId-control-oeCartSearch">Enter Cart Number</label>
							</h3>
							<CartSearch />
						</section>
					}
				</div>
			}
		</div>
	);
});

const CustomerSideMenuNew = observer((props) => {
	const { toolbarStore } = useToolbarStores();
	const { globalDynamicStore, magicModal } = useGlobalContext();
	const {
		menuStore: {
			deliveryAvailabilityLink,
			hasErrors = false,
			lookupStore,
			overlay,
			recentCustomersStore,
		} = {},
	} = props;
	const deliveryDatesHandler = () => {
		const availDelDatesStore = new AvailableDeliveryDatesStore(deliveryAvailabilityLink, globalDynamicStore);

		availDelDatesStore.init();
		magicModal.openModal({
			id: 'available-delivery-dates-modal',
			title: 'Available Delivery Dates',
			maxWidth: 372,
			showCloseButton: true,
			content: {
				children: <AvailableDeliveryDates store={availDelDatesStore} />,
			}
		});
	};
	const todaysCustomersHandler = () => {
		toolbarStore.closeMenu();
		const modalProps = {
			timeFormat: 'h:mm a',
			link: props.menuStore.customersToolbarResults?._links?.recentCustomersByLocationPages?.href,
		};

		magicModal.openModal({
			containerClass: recentCustomerStyles['most-recent-customers-modal'],
			id: 'most-recent-customers-modal',
			title: 'Today’s Customers',
			maxWidth: 520,
			content: {
				children: <Modal {...modalProps} />,
			},
		});
	};

	return (
		<div className={customerStyles.customerSideMenu}>
			<MagicOverlay store={overlay} containerSelector=".oe-side-menu" />
			<div className={styles.sideMenuInside}>
				{
					hasErrors &&
					<div className="tw-text-red tw-mt-4">
						An unknown error occurred. If the problem persists, please open a Help Desk ticket.
					</div>
				}
				{
					Boolean(lookupStore) && Boolean(recentCustomersStore) && !hasErrors &&
					<div className={styles['side-menu-contents']} data-qa="side-menu-contents">
						<h2 className="tw-heading-4 tw-pb-4">Customers</h2>
						<section className={styles.section}>
							<h3
								className="tw-heading-5"
								data-qa="search-customer-title"
							>
								Search For a Customer
							</h3>
							<CustomerLookup store={lookupStore} />
						</section>
						<section className={styles.section}>
							<h3 className="tw-heading-5">My Recent Customers</h3>
							<RecentCustomers store={recentCustomersStore} />
						</section>
						<section className={styles.section}>
							<h3 className="tw-heading-5">More Tools</h3>
							<ul className={customerStyles['customers-side-menu-list']}>
								<li className={customerStyles['customers-side-menu-list-item']}>
									<button
										className="customers-side-menu-button-text button-as-anchor"
										type="button"
										onClick={todaysCustomersHandler}
									>Today’s customers in my location</button>
								</li>
								<li className={customerStyles['customers-side-menu-list-item']}>
									<a
										className="customers-side-menu-button-text button-as-anchor"
										data-qa="my-opportunities-link"
										href="/opportunities"
									>
										My opportunities
									</a>
								</li>
								{
									Boolean(deliveryAvailabilityLink) &&
									<li className={customerStyles['customers-side-menu-list-item']}>
										<button
											className="customers-side-menu-button-text button-as-anchor"
											data-qa="delivery-dates-link"
											onClick={deliveryDatesHandler}
										>
											Available delivery dates by <span className="tw-uppercase">zip</span>
										</button>
									</li>
								}
							</ul>
						</section>
						{
							Boolean(globalDynamicStore.model.proxyCustomerByCartNumberLink) &&
							<section className={styles.section}>
								<h3 className="tw-heading-6 tw-uppercase">
									<label htmlFor="cartId-control-oeCartSearch">Enter Cart Number</label>
								</h3>
								<CartSearch />
							</section>
						}
					</div>
				}
			</div>
		</div>
	);
});

export const CustomerSideMenu = (props) => {
	const { featureTogglesModel } = useGlobalContext();

	if (featureTogglesModel.isOn('ENGAGE_TOOLBAR_REDESIGN')) {
		return <CustomerSideMenuNew {...props} />;
	}
	return <CustomerSideMenuLegacy {...props} />;
};
