'use client';

/* eslint-disable no-restricted-globals */
import React, {
	useCallback, useEffect, useMemo, useRef,
} from 'react';
import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import {
	useRouter as useNextRouter,
} from 'next/navigation';

import { PageViewEventModel } from '~/tracking/pageview-event/PageViewEvent.tracking.model';

import { getAnchorProperty } from '~/app/utils/getAnchorProperties.util';
import { isOnServer } from '~/global/global.constants';
import { VisitorEventModelFactory } from '~/tracking/visitor-event/Models/VisitorEvent.tracking.model';
import { useGlobalContext } from '~/global/Contexts/Global.context';

declare global {
	interface Window {
		routeChangeStarted: boolean;
		upcomingPagesEventDataLayer: any[];
		eventDataLayer: any[];
	}
}

type PushStateInput = [
	data: any,
	unused: string,
	url?: string | URL | null | undefined,
];

export function isSameURL(target: URL, current: URL) {
	const cleanTarget = `${target.protocol}//${target.host}${target.pathname}${target.search}`;
	const cleanCurrent = `${current.protocol}//${current.host}${current.pathname}${current.search}`;

	return cleanTarget === cleanCurrent;
}

export function isSameURLWithoutSearch(target: URL, current: URL) {
	const cleanTarget = `${target.protocol}//${target.host}${target.pathname}`;
	const cleanCurrent = `${current.protocol}//${current.host}${current.pathname}`;

	return cleanTarget === cleanCurrent;
}

if (!isOnServer) {
	window.eventDataLayer = window.eventDataLayer || [];
	window.upcomingPagesEventDataLayer = [];

	if (typeof window.routeChangeStarted === 'undefined') {
		window.routeChangeStarted = false;
	}
}

const tempDynamicLoadedCssModulesFix = () => {
	const allStyleElems = document.querySelectorAll('link[rel="preload"]');
	allStyleElems.forEach((elem) => {
		elem.setAttribute('rel', 'stylesheet');
	});
};

const handleRouteChangeStart = () => {
	tempDynamicLoadedCssModulesFix();
	window.routeChangeStarted = true;
	window.upcomingPagesEventDataLayer = [];
	window.eventDataLayer.push({ event: 'routeChangeStart' });
};

type AnchorElement = HTMLAnchorElement | SVGAElement;
type Target = HTMLElement | Element;

const isAnchorElementType = (subject: EventTarget | null): subject is AnchorElement => {
	return subject instanceof HTMLAnchorElement || subject instanceof SVGAElement;
};
const isTargetType = (subject: EventTarget | null): subject is Target => {
	return subject instanceof HTMLElement || subject instanceof Element;
};

export const RouteTransitionTracker = React.memo(
	() => {
		const elementsWithAttachedHandlers = useRef<(HTMLAnchorElement | SVGAElement)[]>([]);

		const {
			globalDynamicStore,
			personalizationScope,
		} = useGlobalContext();

		const handleRouteChangeComplete = () => {
			tempDynamicLoadedCssModulesFix();
			// console.info('resetting data layer');
			window.routeChangeStarted = false;
			window.eventDataLayer.length = 0;

			if (window.upcomingPagesEventDataLayer.length) {
				const existingItems = window.eventDataLayer.splice(0, window.eventDataLayer.length);
				window.eventDataLayer.push(...window.upcomingPagesEventDataLayer, ...existingItems);
				console.info('adding new pages items to eventDataLayer', window.upcomingPagesEventDataLayer);
				window.upcomingPagesEventDataLayer = [];
			}
			window.eventDataLayer.push({ event: 'routeChangeComplete' });
		};

		useEffect(() => {
			const visitorEventModel = VisitorEventModelFactory.create(globalDynamicStore);

			const pageViewEventModel = new PageViewEventModel(document?.title || 'Room&Board', undefined, undefined, personalizationScope, visitorEventModel);

			pageViewEventModel.trackPageView();

			const startProgress = () => {
				handleRouteChangeStart();
			};

			const stopProgress = () => {
				handleRouteChangeComplete();
			};

			const handleAnchorClick: EventListenerOrEventListenerObject = (event) => {
				if (event.defaultPrevented) {
					return;
				}
				const { currentTarget: anchorElement, target } = event;

				if (!isAnchorElementType(anchorElement) || !isTargetType(target)) {
					return;
				}
				const preventProgress = target.getAttribute('data-prevent-nprogress') === 'true'
					|| anchorElement.getAttribute('data-prevent-nprogress') === 'true';

				if (preventProgress) {
					return;
				}

				const anchorTarget = getAnchorProperty(anchorElement, 'target');

				// Skip anchors with target="_blank"
				if (anchorTarget === '_blank') {
					return;
				}

				// Skip control/command/option/alt+click
				if (event instanceof KeyboardEvent) {
					if (event.metaKey || event.ctrlKey || event.shiftKey || event.altKey) return;
				}

				const targetHref = getAnchorProperty(anchorElement, 'href');
				const targetUrl = new URL(targetHref);
				const currentUrl = new URL(location.href);

				if (isSameURLWithoutSearch(targetUrl, currentUrl)) {
					return;
				}
				if (isSameURL(targetUrl, currentUrl)) {
					return;
				}

				startProgress();
			};

			const handleMutation: MutationCallback = () => {
				const anchorElements = Array.from(document.querySelectorAll('a')) as (
					| HTMLAnchorElement
					| SVGAElement
				)[];

				const validAnchorElements = anchorElements.filter((anchor) => {
					const href = getAnchorProperty(anchor, 'href');
					const isNotTelOrMailto = href
						&& !href.startsWith('tel:')
						&& !href.startsWith('mailto:')
						&& !href.startsWith('blob:')
						// eslint-disable-next-line no-script-url
						&& !href.startsWith('javascript:');

					return (
						isNotTelOrMailto
						&& getAnchorProperty(anchor, 'target') !== '_blank'
					);
				});

				validAnchorElements.forEach((anchor) => {
					anchor.addEventListener('click', handleAnchorClick, true);
				});
				elementsWithAttachedHandlers.current = validAnchorElements;
			};

			const mutationObserver = new MutationObserver(handleMutation);
			mutationObserver.observe(document, { childList: true, subtree: true });

			const originalWindowHistoryPushState = window.history.pushState;
			window.history.pushState = new Proxy(window.history.pushState, {
				apply: (target, thisArg, argArray: PushStateInput) => {
					stopProgress();
					return target.apply(thisArg, argArray);
				},
			});

			return () => {
				mutationObserver.disconnect();
				elementsWithAttachedHandlers.current.forEach((anchor) => {
					anchor.removeEventListener('click', handleAnchorClick);
				});
				elementsWithAttachedHandlers.current = [];
				window.history.pushState = originalWindowHistoryPushState;
			};
		}, []);

		return null;
	},
);

export type RNBRouter = {
	push: (href: string, options?: NavigateOptions) => void;
	replace: (href: string, options?: NavigateOptions) => void;
	back: () => void;
	refresh: () => void;
}

export function useRNBRouter(): RNBRouter {
	const router = useNextRouter();

	const startProgress = useCallback(
		() => {
			console.info('startProgress');
			// NProgress.start();
		},
		[router],
	);

	const progress = useCallback(
		(
			href: string,
			options?: NavigateOptions,
		) => {
			const currentUrl = new URL(location.href);
			const targetUrl = new URL(href, location.href);

			if (
				isSameURL(targetUrl, currentUrl)
			) return router.push(href, options);

			startProgress();
		},
		[router],
	);

	const refresh = useCallback(
		() => {
			startProgress();

			return router.refresh();
		},
		[router],
	);

	const push = useCallback(
		(
			href: string,
			options?: NavigateOptions,
		) => {
			progress(href, options);
			return router.push(href, options);
		},
		[router, startProgress],
	);

	const replace = useCallback(
		(
			href: string,
			options?: NavigateOptions,
		) => {
			progress(href, options);
			return router.replace(href, options);
		},
		[router, startProgress],
	);

	const back = useCallback(
		() => {
			startProgress();

			return router.back();
		},
		[router],
	);

	const enhancedRouter = useMemo(() => {
		return {
			...router, push, replace, back, refresh,
		};
	}, [router, push, replace, back, refresh]);

	return enhancedRouter;
}
