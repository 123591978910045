// This file is @deprecated ENGAGE_TOOLBAR_REDESIGN
import {
	action, computed, observable, makeObservable,
} from 'mobx';
import axios from 'axios';
import { registerRootStore } from 'mobx-keystone';

import { type CustomerLookupStoreFactoryOptions } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/CustomerLookup.store';
import { type CustomerSideMenuStore } from '~/engage/toolbar/customer-side-menu/Stores/CustomerSideMenu.store';
import { OpportunitiesStore } from '~/engage/opportunities/Stores/Opportunities.store.root';
import { isEmpty } from '~/util/isEmpty';
import { isString } from '~/util/isString';
import { redirect } from '~/util/redirect';
import { IncognitoLocationStore } from '~/engage/toolbar/incognito-location/Stores/IncognitoLocation.store';
import { OpenDeliverySummaryModel } from '~/engage/toolbar/open-delivery-summary/Models/OpenDeliverySummary.model';
import { GlobalStatic } from '~/global/global-static/Models/GlobalStatic.model';
import { OpenDeliverySummaryStoreFactory } from '~/engage/toolbar/open-delivery-summary/Stores/OpenDeliverySummary.store';

const menuNames = {
	home: 'home',
	customers: 'customers',
	shop: 'shop',
	quicklinks: 'quicklinks',
	newGuest: 'newGuest',
	profile: 'profile',
} as const;

type MenuName = typeof menuNames;
export type MenuNameKey = keyof MenuName;
type Context = {
	globalStaticStore: any
	globalDynamicStore: any
	magicModal: any
	magicOverlay: unknown
	featureTogglesModel: any
	isOnCheckout: boolean
};
export type MenuItemLayout = {
	pos?: {
		x: number
		y: number
	}
	width?: number
	right?: number,
};
export type MenuItem = {
	title: string
	isActive: boolean,
	type: 'DROPDOWN' | 'STATIC' | 'SIDEMENU'
	renderer: string
	iconRenderer: string
	href?: string
	// anchorPoint is where you want the dropdown to attach to the menu button. Only accepts "left" or "right".
	anchorPoint?: 'left' | 'right'
	// offsetDirection is used to determine what CSS position to offset from. Only accepts "left" or "right".
	offsetDirection?: 'left' | 'right'
	// Applies this offset value after calculating position.
	offset?: number
	layout?: MenuItemLayout
	store?: any
};
type Menus = {
	[key in MenuNameKey]: MenuItem
};
export const isMenuNameKeyType = (
	obj: MenuNameKey | MenuItem,
): obj is MenuNameKey => {
	return isString(obj);
};
export type MyOpportunityDto = {
	dateCreated?: string
	employeeName?: string
	isMyOpportunity: boolean
	_links?: {
		createNewOpportunity?: {
			href: string
		}
		reassignOpportunity?: {
			href: string
		}
		removeOpportunity?: {
			href: string
		}
		viewOpportunities?: {
			href: string
		}
	}
};
export type MenuItemOptions = {
	customers?: {
		customerLookupStoreFactoryOptions?: CustomerLookupStoreFactoryOptions
	}
};
export class ToolbarStoreLegacy {
	magicOverlay: unknown;

	customerSideMenuStore: CustomerSideMenuStore;

	featureTogglesModel: any;

	menus: Menus;

	openDeliveryModel?: OpenDeliverySummaryModel;

	// pull list of locations here
	incognitoFormStore: IncognitoLocationStore | null = null;

	isWorkingWithReadOnly = false;

	openDeliverySummaryStore: any;

	globalStaticStore;

	globalDynamic;

	magicModal;

	opportunities?: OpportunitiesStore;

	constructor(customerSideMenuStore: CustomerSideMenuStore, context: Context) {
		makeObservable(this, {
			menus: observable,
			openDeliveryModel: observable.ref,
			incognitoFormStore: observable,
			isWorkingWithReadOnly: observable,
			globalStaticStore: observable,
			globalDynamic: observable,
			magicModal: observable,
			isIncognitoMode: computed,
			globalStatic: computed,
			locationSelectorLink: computed,
			createAccountLink: computed,
			hasActiveSideMenu: computed,
			hasActiveDropdown: computed,
			isStatic: computed,
			activeMenu: computed,
			openDeliveryGroupsLink: computed,
			fetchOpenDeliveryGroups: action.bound,
			setOrToggleActiveMenu: action.bound,
			setMenuLayout: action,
			setActiveMenu: action.bound,
			closeMenu: action.bound,
			shopWithNewGuest: action.bound,
		});

		this.magicOverlay = context.magicOverlay;
		this.customerSideMenuStore = customerSideMenuStore;
		this.globalStaticStore = context.globalStaticStore;
		this.globalDynamic = context.globalDynamicStore;
		this.magicModal = context.magicModal;
		this.featureTogglesModel = context.featureTogglesModel;

		this.menus = {
			home: {
				title: 'Home',
				isActive: false,
				type: 'STATIC',
				renderer: 'Home',
				iconRenderer: 'Home',
				href: '/',
			},
			customers: {
				title: 'Customers',
				isActive: false,
				type: 'SIDEMENU',
				renderer: 'CustomerSideMenu',
				iconRenderer: 'Customers',
				store: this.customerSideMenuStore,
			},
			shop: {
				title: 'Shop',
				isActive: false,
				type: 'SIDEMENU',
				renderer: 'Shop',
				iconRenderer: 'Shop',
			},
			quicklinks: {
				title: 'Quick Links',
				isActive: false,
				type: 'SIDEMENU',
				renderer: 'QuickLinks',
				iconRenderer: 'QuickLinks',
			},
			newGuest: {
				title: 'New guest',
				isActive: false,
				type: 'STATIC',
				renderer: 'NewGuest',
				iconRenderer: 'NewGuest',
			},
			profile: {
				get title() {
					if (!context.globalDynamicStore.hasInitiallyLoaded) {
						return '';
					}
					return context.globalDynamicStore.model.employeeInitials;
				},
				isActive: false,
				type: 'DROPDOWN',
				anchorPoint: 'right',
				offsetDirection: 'right',
				offset: 0,
				renderer: 'Profile',
				iconRenderer: 'Circle',
				layout: {
					right: 0,
				},
			},
		};

		this.initMyOpportunities();
	}

	get globalStatic() {
		return this.globalStaticStore?.model;
	}

	get isIncognitoMode() {
		return !!this.locationSelectorLink;
	}

	get locationSelectorLink() {
		return this.globalDynamic.model.locationSelectorLink || null;
	}

	get createAccountLink() {
		return this.globalDynamic.model.createAccountLink || null;
	}

	get hasActiveSideMenu() {
		return this.activeMenu?.type === 'SIDEMENU';
	}

	get hasActiveDropdown() {
		return this.activeMenu?.type === 'DROPDOWN';
	}

	get isStatic() {
		return this.activeMenu?.type === 'STATIC';
	}

	get activeMenu() {
		return Object.values(this.menus)?.find?.(menu => menu.isActive);
	}

	get openDeliveryGroupsLink() {
		return this.globalDynamic.model.openDeliveryGroupsLink;
	}

	hasMyOpportunity(value: unknown): value is { myOpportunity: MyOpportunityDto } {
		return (
			typeof value === 'object'
			&& value !== null
			&& 'myOpportunity' in value
			&& value.myOpportunity !== null
		);
	}

	initMyOpportunities(myOpportunityData?: MyOpportunityDto) {
		this.opportunities = new OpportunitiesStore({});
		this.opportunities.globalDynamic = this.globalDynamic;
		this.opportunities.magicOverlay = this.magicOverlay;
		this.opportunities.magicModal = this.magicModal;

		if (myOpportunityData) {
			this.opportunities.updateToolbar(myOpportunityData);
			registerRootStore(this.opportunities);
		} else {
			this.globalDynamic.registerClientSideInitialLoadCallback((globalDynamicModel: unknown) => {
				if (!this.hasMyOpportunity(globalDynamicModel) || !this.opportunities) {
					return;
				}
				this.opportunities.updateToolbar(globalDynamicModel.myOpportunity);
				this.openDeliverySummaryStore = OpenDeliverySummaryStoreFactory.create(
					this.openDeliveryGroupsLink,
				);
				registerRootStore(this.opportunities);
			});
		}
	}

	fetchOpenDeliveryGroups() {
		if (!this.openDeliveryGroupsLink) {
			return Promise.resolve(false);
		}
		return axios.get(this.openDeliveryGroupsLink).then((resp) => {
			if (!resp?.data) {
				return;
			}
			this.openDeliveryModel = new OpenDeliverySummaryModel();
			this.openDeliveryModel.data = resp.data;
		});
	}

	setOrToggleActiveMenu(menuToToggle: MenuNameKey | MenuItem, layout?: MenuItemLayout, options?: MenuItemOptions) {
		let theMenuItem = menuToToggle;

		if (isMenuNameKeyType(menuToToggle)) {
			theMenuItem = this.menus?.[menuToToggle] || {};
		}
		if (isMenuNameKeyType(theMenuItem)) {
			return;
		}
		if (theMenuItem.isActive) {
			this.closeMenu();
		} else {
			this.setMenuLayout(theMenuItem, layout);
			this.setActiveMenu(theMenuItem, options);

			if (theMenuItem.type === 'DROPDOWN' && this.isIncognitoMode && this.incognitoFormStore === null) {
				this.incognitoFormStore = new IncognitoLocationStore(this.locationSelectorLink, {
					location: this.globalDynamic.model.storeName,
				});
				this.incognitoFormStore.initForm();
				this.incognitoFormStore.fetchLocations();
			}
		}
	}

	setMenuLayout(menuItem: MenuItem, layout?: MenuItemLayout) {
		menuItem.layout = menuItem.layout || {};
		menuItem.layout.pos = layout?.pos;
		menuItem.layout.width = layout?.width;
	}

	setActiveMenu(menuToActivate: MenuItem, options?: MenuItemOptions) {
		this.closeMenu();
		if (menuToActivate) {
			menuToActivate.isActive = true;
			if (menuToActivate.store) {
				menuToActivate.store.load(options);
			}
		}
	}

	closeMenu() {
		if (this.activeMenu) {
			this.activeMenu.isActive = false;
		}
	}

	shopWithNewGuest() {
		const newGuestFunction = (staticModel: GlobalStatic) => {
			const newGuestLink = staticModel.shopWithNewGuestLink;
			axios.post(newGuestLink).then(() => {
				this.globalDynamic.fetchData().then(() => {
					window.setTimeout(() => {
						redirect('/');
					}, 100);
				});
			});
		};

		if (!isEmpty(this.globalStatic.shopWithNewGuestLink)) {
			newGuestFunction(this.globalStatic);
		} else {
			this.globalStaticStore.registerCallbackFunction(newGuestFunction);
		}
	}
}
