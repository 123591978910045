import React from 'react';
import { observer } from 'mobx-react';

import { Field } from '~/util/formz/Components/fields/Field';

import styles from '~/engage/available-delivery-dates/Components/delivery-dates-modal.module.scss';

export const DeliveryDatesResults = observer((props = {}) => {
	const {
		store,
		store: {
			form,
			form: {
				fields,
			} = {},
			results: {
				deliveryAvailability = [],
				legacyDeliveryAvailability = [],
			} = {},
		} = {},
	} = props;
	const submitProps = {
		'id': 'delivery-date-lookup-button',
		'data-qa': 'delivery-date-lookup-button',
		'type': 'submit',
		'className': 'Button',
		'value': 'Submit',
		...props.submitProps,
	};

	if (form.plugins.formValidator.hasErrors || form.model.zipCode.length < 5) {
		submitProps.disabled = true;
		submitProps.className += ' disabled';
	}
	const results = deliveryAvailability.length ? deliveryAvailability : legacyDeliveryAvailability;

	if (!results.length) {
		return (
			<p className={styles['delivery-dates-disclaimer']}>
				Add items of interest to the cart to see available delivery dates by ZIP. Delivery availability is based
				on the volume of the items in the cart.
			</p>
		);
	}

	return (
		<div className={styles['delivery-dates-content']}>
			{
				store.editMode &&
				<div className={styles['edit-zip-form-container']}>
					<form {...form.reactProps}>
						<Field field={fields.zipCode} showValidationMessage={false} />
						<input {...submitProps} />
					</form>
				</div>
			}
			{
				!store.editMode &&
				<div className={styles['edit-zip-container']}>
					<span className="tw-uppercase">zip</span> Code: <strong data-qa="zip-holder">{props.zipCode.substring(0, 5)}</strong>
					<button
						className="button-as-anchor"
						onClick={store.editZip}
						data-qa="edit-zip-delivery-date-btn"
					>Edit <span className="tw-uppercase">zip</span> Code</button>
				</div>
			}
			{
				results.slice(0, 7).map((result, index) => {
					const {
						date = '',
						fromDate = '',
						toDate = '',
					} = result;
					const dateToUse = fromDate || date;
					let formattedDate = '';

					const dateOptions = {
						timeZone: 'UTC',
						weekday: 'short',
						month: 'short',
						day: '2-digit',
					};

					formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(dateToUse));
					if (toDate && dateToUse !== toDate) {
						formattedDate += ` to ${new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(toDate))}`;
					}

					return (
						<div key={`delivery-result-${index}`} className={styles['delivery-result']} data-qa="delivery-result">
							{`${formattedDate} `}
							{
								Boolean(result.stops) &&
								<span>({result.stops})</span>
							}
						</div>
					);
				})
			}
			<p className={styles['delivery-dates-disclaimer']}>
				Delivery dates estimated and not guaranteed until checkout. Dates apply only to in-stock items for In-home Delivery.
			</p>
		</div>
	);
});
