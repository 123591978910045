// @deprecated ENGAGE_TOOLBAR_REDESIGN
import { action, observable, makeObservable } from 'mobx';

import {
	type MenuItemOptions, type MenuItem, type MenuNameKey, type MenuItemLayout,
} from '~/engage/toolbar/Stores/Toolbar.store.legacy';

class ToolbarActions {
	store: any;

	constructor() {
		makeObservable(this, {
			store: observable,
			setStore: action.bound,
			setOrToggleActiveMenu: action.bound,
			closeMenu: action.bound,
			setMenuLayout: action,
			setActiveMenu: action.bound,
		});
	}

	setStore(store: any) {
		this.store = store;
	}

	setOrToggleActiveMenu(menuToToggle: MenuItem | MenuNameKey, layout?: MenuItemLayout, options?: MenuItemOptions) {
		this.store?.setOrToggleActiveMenu(menuToToggle, layout, options);
	}

	closeMenu() {
		this.store?.closeMenu();
	}

	setMenuLayout(menuItem: any, layout: any) {
		this.store?.setMenuLayout(menuItem, layout);
	}

	setActiveMenu(menuToActivate: any) {
		this.closeMenu();
		if (menuToActivate) {
			menuToActivate.isActive = true;
		}
	}
}

export { ToolbarActions };
