'use client';

import React, { useEffect } from 'react';
import { observer, Provider } from 'mobx-react';

import { ViewState } from '~/util/viewState/Components/ViewState';

export const CreateAccount = observer((props) => {
	useEffect(() => () => {
		if (!props.createAccountStore?.createAccountFormStore) {
			return;
		}
		props.createAccountStore.createAccountFormStore.disposers.forEach((disposer) => {
			if (typeof disposer !== 'function') {
				return;
			}
			disposer();
		});
	}, []);

	return (
		<Provider createAccountStore={props.createAccountStore}>
			<ViewState viewState={props.createAccountStore.viewState} />
		</Provider>
	);
});
