'use client';

import React from 'react';
import { observer } from 'mobx-react';

import { ToolbarStoreProvider } from '~/engage/toolbar/Contexts/Toolbar.context';
import { type AccountExistsCheckoutErrorMessageProps, AccountExistsCheckoutErrorMessage } from '~/util/messaging/promise-error/AccountExistsCheckoutErrorMessage';

export const UsernameUniqueConstraintErrorMessage = observer((props: AccountExistsCheckoutErrorMessageProps) => {
	return (
		<ToolbarStoreProvider>
			<AccountExistsCheckoutErrorMessage {...props} />
		</ToolbarStoreProvider>
	);
});
