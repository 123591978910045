import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { type EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { type ToolbarStoreLegacy } from '~/engage/toolbar/Stores/Toolbar.store.legacy';
import { AdvancedSearchStoreFactory } from '~/engage/shop-search/advanced-search/Stores/AdvancedSearch.store';
import { Field } from '~/util/formz/Components/fields/Field';
import { useToolbarStores } from '~/engage/toolbar/Contexts/Toolbar.context';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useSafeSpread } from '~/hooks/useSafeSpread';

export const isToolbarStoreLegacyType = (
	subject: EngageToolbarStore | ToolbarStoreLegacy,
): subject is ToolbarStoreLegacy => {
	return 'activeMenu' in subject;
};

export const AdvancedSearch = observer(() => {
	const { toolbarStore } = useToolbarStores();
	const { magicModal, globalDynamicStore } = useGlobalContext();
	const [store, setStore] = useState(AdvancedSearchStoreFactory.create(magicModal, toolbarStore, globalDynamicStore));
	const { form } = store;
	const [formProps, formKey] = useSafeSpread(form.reactProps);

	useEffect(() => {
		if (isToolbarStoreLegacyType(toolbarStore)) {
			return;
		}
		if (!toolbarStore.hasActiveSideMenu) {
			return;
		}
		setStore(AdvancedSearchStoreFactory.create(magicModal, toolbarStore, globalDynamicStore));
	}, [toolbarStore.hasActiveSideMenu]);

	return (
		<form key={formKey} {...formProps}>
			<Field field={form.fields.productName} />
			<Field field={form.fields.collectionName} />
			<input
				type="submit"
				data-qa="advanced-shop-search-button"
				className="ButtonTertiary"
				value="Search"
			/>
		</form>
	);
});
