import axios from 'axios';
import { action, observable, makeObservable } from 'mobx';

import { redirect } from '~/util/redirect';
import { rootRelativeDynaSitePath } from '~/global/global.constants';
import { cartSearchFormSettings } from '~/engage/cart-search/Stores/cartSearchFormSettings';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { cartChangedAction } from '~/cart/Actions/cartChanged.action';

// RNB.Engage.CartSearch.storeFactory
class CartSearchStore {
	model = {
		cartId: '',
	};

	toolbarStore;

	globalDynamicModel;

	featureTogglesModel;

	constructor() {
		makeObservable(this, {
			model: observable,
			toolbarStore: observable,
			globalDynamicModel: observable,
			submitHandler: action.bound,
		});
	}

	submitHandler(event) {
		const proxyCustByCartNumLink = this.globalDynamicModel.proxyCustomerByCartNumberLink;

		event.preventDefault();
		this.form.plugins.formValidator.validateForm();
		if (!this.form.plugins.formValidator.hasErrors) {
			if (!proxyCustByCartNumLink) {
				return;
			}
			axios.post(proxyCustByCartNumLink, { cartNumber: this.model.cartId }).then(() => {
				if (!((/cart/i)).test(window.location.href)) {
					redirect(`${rootRelativeDynaSitePath}shop/shoppingcart/cartDetails.do`);
				} else {
					this.toolbarStore.closeMenu();
					cartChangedAction.dispatch();
				}
			}, (error) => {
				this.form.plugins.formValidator.validationMessages.set('cartId', {
					error: error.response.status === 404 ? 'Cart Number not found' : error.response.message,
				});
			});
		}
	}
}

const CartSearchStoreFactory = {
	create: (toolbarStore, globalDynamicModel, featureTogglesModel = {}) => {
		const instance = new CartSearchStore();

		instance.globalDynamicModel = globalDynamicModel;
		instance.featureTogglesModel = featureTogglesModel;
		instance.toolbarStore = toolbarStore;
		instance.formSettings = cartSearchFormSettings(instance.submitHandler);
		instance.form = new FormBuilder(instance.model, instance.formSettings);

		return instance;
	},
};

export { CartSearchStoreFactory };
