import React from 'react';

import { type EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { SearchResults } from '~/engage/customer-search/search-results/Components/SearchResults';
import { SearchResultsStore } from '~/engage/customer-search/search-results/Stores/SearchResults.store';
import { SearchLink } from '~/engage/customer-search/search-results/customer-linking/Components/SearchLink';
import { CustomerLinkingStore } from '~/engage/customer-search/search-results/customer-linking/Stores/CustomerLinking.store';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { LinkSuccess } from '~/engage/customer-search/search-results/customer-linking/Components/LinkSuccess';
import { redirect } from '~/util/redirect';
import { cartChangedAction } from '~/cart/Actions/cartChanged.action';
import { noop } from '~/util/noop';
import type { Customer } from '~/engage/customer-search/search-results/Types/Customer.interface';
import type { Details } from '~/engage/customer-search/search-results/customer-linking/Stores/CustomerLinking.store';
import { favoritesChangedAction } from '~/favorites/Actions/favoritesChanged.action';

interface SearchResultsViewParameters {
	globalDynamicStore: any
	continueWithoutMatchingHandler: () => void
	partialMatchFound: boolean
	errorResponse: any
	searchResults: any
	search: any
}

class SearchResultsUiStore {
	searchParams: any;

	constructor(searchParams: {
		search?: any
		searchResults?: any
		partialMatchFound?: boolean
		continueWithoutMatchingHandler?: () => void
		errorResponse?: any
		magicModal?: any
		featureTogglesModel: any
		globalDynamicStore?: any
		toolbarActions?: any
		globalStaticModel?: any
		HREF?: any
		toolbarStore?: EngageToolbarStore
	}) {
		this.searchParams = searchParams;
	}

	viewState = new ViewStateStore(this, {
		searchResults: {
			component: SearchResults,
			onEnter: (_, store, parameters: SearchResultsViewParameters) => {
				const {
					globalDynamicStore,
					continueWithoutMatchingHandler,
					partialMatchFound = false,
					errorResponse,
					searchResults,
					search,
				} = parameters;

				const {
					magicModal,
					globalStaticModel,
					toolbarActions,
					featureTogglesModel,
					HREF,
					toolbarStore,
				} = store.searchParams || {};

				if (!store.searchResultsStore) {
					store.searchResultsStore = new SearchResultsStore(
						store.viewState,
						globalStaticModel,
						globalDynamicStore,
						toolbarActions,
						magicModal,
						featureTogglesModel,
						HREF,
						toolbarStore,
					);
				}
				if (searchResults && errorResponse) {
					// Just set the search results if we have them
					store.searchResultsStore.errorResponse = errorResponse;
					store.searchResultsStore.searchParameters = search;
					store.searchResultsStore.results = store.searchResultsStore.mapResultsDataToModel(searchResults);
				} else if (searchResults) {
					// Just set the search results if we have them
					store.searchResultsStore.searchParameters = search;
					store.searchResultsStore.results = store.searchResultsStore.mapResultsDataToModel(searchResults);
				} else if (search) {
					// Otherwise, get the results via REST
					store.searchResultsStore.loadSearchResults(search);
				}
				const title = partialMatchFound
					? 'Partial Match Found'
					: <span className="tw-break-all">{store.searchResultsStore.title}</span>;

				magicModal.alterModal({
					title,
					width: 650,
				});
				return {
					searchResultsStore: store.searchResultsStore,
					partialMatchFound,
					continueWithoutMatchingHandler,
					globalDynamic: globalDynamicStore,
					magicModal,
					proxyCustomer(href: string) {
						store.searchResultsStore.proxyCustomer(href);
					}
				};
			}
		},
		linkCustomer: {
			component: SearchLink,
			onEnter: (_, store, parameters: { customer: Customer }) => {
				const customerLinkStore = new CustomerLinkingStore(parameters.customer, store.viewState);
				const modalTitle = parameters.customer.type === 'LINKED_MULTI' ? 'Select customer: ' : 'Linking to customer: ';

				customerLinkStore.loadCustomerLinkData();
				this.searchParams.magicModal.alterModal({ title: modalTitle, width: 650 });
				return {
					customerLinkStore,
					proxyCustomer(href: string) {
						store.searchResultsStore.proxyCustomer(href);
					}
				};
			}
		},
		processing: {
			component: () => (
				<div style={{ minHeight: '200px' }}>
					<LoadingSpinner isLoading />
				</div>
			)
		},
		linkCustomerSuccess: {
			component: LinkSuccess,
			onEnter: (viewState, store, parameters: {proxyLandingPage?: string, customer: Customer, details: Details}) => {
				const modalConfig = {
					title: 'Success! Accounts linked',
					width: 650,
					onCloseModal: noop,
				};

				if (parameters.proxyLandingPage) {
					modalConfig.onCloseModal = () => {
						redirect(parameters.proxyLandingPage as string);
					};
				}
				this.searchParams.globalDynamicStore.fetchData();
				this.searchParams.magicModal.alterModal(modalConfig);

				if (cartChangedAction) {
					cartChangedAction.dispatch();
				}

				if (favoritesChangedAction && favoritesChangedAction.getNumListeners()) {
					favoritesChangedAction.dispatch();
				}

				return {
					customer: parameters.customer,
					details: parameters.details
				};
			}
		}
	});

	init() {
		this.viewState.goTo('searchResults', this.searchParams);
	}
}

export { SearchResultsUiStore };
