'use client';

import React, {
	createContext, useContext, useEffect, useState
} from 'react';

import { isOnServer } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { ToolbarStoreLegacy } from '~/engage/toolbar/Stores/Toolbar.store.legacy';
import { ToolbarActions } from '~/engage/toolbar/Stores/Toolbar.actions';
import { CustomerSideMenuStoreFactory } from '~/engage/toolbar/customer-side-menu/Stores/CustomerSideMenu.store';
import { MyOpportunityTrackingStore } from '~/tracking/my-opportunity-events/Stores/MyOpportunity.tracking.store';
import { EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { GlobalDynamic } from '~/global/global-dynamic/Models/GlobalDynamic.model';
import { useRNBRouter } from '~/hooks/useRNBRouter';

export type ToolbarStores = {
	toolbarStore: EngageToolbarStore | ToolbarStoreLegacy
	toolbarActions: ToolbarActions
};

const toolbarStoreContext = createContext<ToolbarStores | null>(null);
const { Provider } = toolbarStoreContext;
// Singleton
let toolbarStores: ToolbarStores | null = null;

export const ToolbarStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const {
		clientSidePageTransition: {
			store: magicOverlay,
		},
		globalStaticModel,
		globalStaticStore,
		globalDynamicStore,
		magicModal,
		featureTogglesModel,
		HREF,
	} = useGlobalContext();

	const router = useRNBRouter();
	const [isGlobalDynamicLoaded, setGlobalDynamicLoaded] = useState(globalDynamicStore.hasInitiallyLoaded);

	const waitForGlobalDynamic = async () => {
		await new Promise((resolve) => {
			globalDynamicStore.registerClientSideInitialLoadCallback((model: GlobalDynamic) => {
				console.info('globalDynamicStore.registerClientSideInitialLoadCallback', model);
				resolve(model);
			});
		});

		setGlobalDynamicLoaded(true);
	};

	useEffect(() => {
		if (!isGlobalDynamicLoaded && !toolbarStores && !isOnServer) {
			waitForGlobalDynamic();
		}
	}, []);

	if (toolbarStores && !isOnServer) {
		return <Provider value={toolbarStores}>{children}</Provider>;
	}

	const toolbarActions = new ToolbarActions();
	let toolbarStore;
	const customersMenu = CustomerSideMenuStoreFactory.create(
		globalStaticModel,
		globalDynamicStore,
		magicModal,
		toolbarActions,
		featureTogglesModel,
		HREF,
	);

	if (featureTogglesModel.isOn('ENGAGE_TOOLBAR_REDESIGN')) {
		toolbarStore = EngageToolbarStore.create({
			magicOverlay,
			router,
		});
		customersMenu.toolbarStore = toolbarStore;
		toolbarStore.setCustomersMenu(customersMenu);
		toolbarStore.opportunities.globalDynamic = globalDynamicStore;
		toolbarStore.opportunities.magicOverlay = magicOverlay;
		toolbarStore.opportunities.magicModal = magicModal;
		toolbarStore.opportunities.featureTogglesModel = featureTogglesModel;
	} else {
		// @ts-ignore
		toolbarStore = new ToolbarStoreLegacy(customersMenu, {
			magicOverlay,
			globalStaticStore,
			globalDynamicStore,
			magicModal,
			featureTogglesModel,
		});
	}

	MyOpportunityTrackingStore.router = router;
	MyOpportunityTrackingStore.globalDynamicStore = globalDynamicStore;

	toolbarActions.setStore(toolbarStore);
	toolbarStores = {
		toolbarStore,
		toolbarActions,
	};
	return <Provider value={toolbarStores}>{children}</Provider>;
};

export const useToolbarStores = () => {
	const theContext = useContext(toolbarStoreContext);

	if (!theContext) {
		throw new Error('No toolbarStoreContext found.');
	}
	return theContext;
};
