'use client';

import React from 'react';
import cn from 'classnames';

import { type EngageToolbarStore, type MenuItemOptions } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { type MenuItemOptions as MenuItemOptionsLegacy } from '~/engage/toolbar/Stores/Toolbar.store.legacy';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useToolbarStores } from '~/engage/toolbar/Contexts/Toolbar.context';
import { TextButton } from '~/components/Buttons/Components/TextButton';

export type AccountExistsCheckoutErrorMessageProps = {
	values: {
		email: string
	}
	inline?: boolean
};

export const AccountExistsCheckoutErrorMessage = ({ inline, values }: AccountExistsCheckoutErrorMessageProps) => {
	const { email } = values;
	const { magicModal, featureTogglesModel } = useGlobalContext();
	const toolbarStores = useToolbarStores();
	const { toolbarActions } = toolbarStores;
	const toolbarStore = toolbarStores.toolbarStore as EngageToolbarStore;

	return (
		<div data-qa="account-exists-checkout-error-message" className={cn({ 'tw-text-red': inline })}>
			An account already exists for this email address. Please{' '}
			<TextButton
				type="button"
				data-qa="search-this-customer-button"
				onClick={() => {
					let options: MenuItemOptions | MenuItemOptionsLegacy;

					if (featureTogglesModel.isOn('ENGAGE_TOOLBAR_REDESIGN')) {
						options = {
							customers: {
								customerLookupStoreFactoryOptions: {
									emailLookupStoreFactoryOptions: {
										defaultEmailLookupFormModel: {
											email,
										},
									},
								},
							},
							withMenuStateChange: true,
						};
					} else {
						options = {
							customers: {
								customerLookupStoreFactoryOptions: {
									emailLookupStoreFactoryOptions: {
										defaultEmailLookupFormModel: {
											email,
										},
									},
								},
							},
						};
					}
					magicModal.closeModal();
					if (featureTogglesModel.isOn('ENGAGE_TOOLBAR_REDESIGN')) {
						toolbarStore.toggleMenu('CUSTOMERS', options);
					} else {
						toolbarActions.setOrToggleActiveMenu('customers', undefined, options);
					}
				}}
			>
				search for this customer
			</TextButton>
			.
		</div>
	);
};
