import React from 'react';
import { observable, action, makeObservable } from 'mobx';

import { type EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { type ToolbarStoreLegacy } from '~/engage/toolbar/Stores/Toolbar.store.legacy';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { SearchResultsModal } from '~/engage/shop-search/search-results/Components/SearchResultsModal';
import { advancedSearchFormSettings } from '~/engage/shop-search/advanced-search/Stores/advancedSearchFormSettings';

type ToolbarStore = EngageToolbarStore | ToolbarStoreLegacy;

class AdvancedSearchStore {
	model = {
		productName: '',
		collectionName: ''
	};

	form: any = {};

	toolbarStore!: ToolbarStore;

	magicModal: any;

	globalDynamicStore: any;

	constructor() {
		makeObservable(this, {
			model: observable,
			form: observable,
			toolbarStore: observable,
			magicModal: observable,
			globalDynamicStore: observable,
			submitHandler: action.bound
		});
	}

	submitHandler(event: React.FormEvent<HTMLFormElement>) {
		this.form.plugins.formValidator.validateForm();
		if (this.form.plugins.formValidator.hasErrors) {
			return;
		}
		this.toolbarStore.closeMenu();
		this.magicModal.openModal({
			maxWidth: 630,
			content: {
				children: (
					<SearchResultsModal
						searchParameters={this.form.model}
						toolbarStore={this.toolbarStore}
						magicModal={this.magicModal}
						globalDynamicStore={this.globalDynamicStore}
					/>
				),
			},
		}, event);
	}
}

const AdvancedSearchStoreFactory = {
	create: (magicModal: any, toolbarStore: ToolbarStore, globalDynamicStore: any) => {
		const instance = new AdvancedSearchStore();

		instance.form = new FormBuilder(instance.model, advancedSearchFormSettings(instance.submitHandler));
		instance.magicModal = magicModal;
		instance.toolbarStore = toolbarStore;
		instance.globalDynamicStore = globalDynamicStore;

		return instance;
	}
};

export { AdvancedSearchStoreFactory };
