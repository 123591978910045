import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { isToolbarStoreLegacyType } from '~/engage/shop-search/advanced-search/Components/AdvancedSearch';
import { Field } from '~/util/formz/Components/fields/Field';
import { QuickSearchStoreFactory } from '~/engage/shop-search/quick-search/Stores/QuickSearch.store';
import { useToolbarStores } from '~/engage/toolbar/Contexts/Toolbar.context';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useSafeSpread } from '~/hooks/useSafeSpread';

export const QuickSearch = observer(() => {
	const { toolbarStore } = useToolbarStores();
	const {
		magicModal,
		globalDynamicStore,
		featureTogglesModel,
	} = useGlobalContext();
	const [shopQuickSearchStore, setShopQuickSearchStore] = useState(QuickSearchStoreFactory.create(
		magicModal,
		toolbarStore,
		globalDynamicStore,
		featureTogglesModel,
	));
	const { form } = shopQuickSearchStore;
	const [formProps, formKey] = useSafeSpread(form.reactProps);

	useEffect(() => {
		if (isToolbarStoreLegacyType(toolbarStore)) {
			return;
		}
		if (!toolbarStore.hasActiveSideMenu) {
			return;
		}
		setShopQuickSearchStore(
			QuickSearchStoreFactory.create(
				magicModal,
				toolbarStore,
				globalDynamicStore,
				featureTogglesModel,
			),
		);
	}, [toolbarStore.hasActiveSideMenu]);

	return (
		<form key={formKey} {...formProps}>
			<Field field={form.fields.articleNumber} />
			<input
				type="submit"
				data-qa="quick-search-find-item-button"
				className="ButtonTertiary tw-flex-shrink-0"
				value="Find Item"
			/>
		</form>
	);
});
