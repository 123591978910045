import React from 'react';

import { giftCardsBalanceUrl } from '~/global/global.constants';

import legacyStyles from '~/engage/toolbar/side-menu/Components/side-menu.legacy.module.scss';
import styles from '~/engage/toolbar/side-menu/Components/side-menu.module.scss';
import { useGlobalContext } from '~/global/Contexts/Global.context';

// @deprecated ENGAGE_TOOLBAR_REDESIGN
const LegacyQuickLinks = () => {
	return (
		<div className={`${legacyStyles['side-menu-contents']} ${legacyStyles['side-menu-contents-quicklinks']}`} data-qa="side-menu-contents">
			<section className={legacyStyles.section}>
				<h3 className="tw-heading-6 tw-uppercase">Samples & Swatches</h3>
				<ul>
					<li><a href="/ideas-advice/explore-materials/fabrics/indoor">Fabric & Leather Swatches</a></li>
					<li><a href="/catalog/dining-and-kitchen/material-samples">Paid Material Samples</a></li>
				</ul>
			</section>
			<section className={legacyStyles.section}>
				<h3 className="tw-heading-6 tw-uppercase">Guides & Care</h3>
				<ul>
					<li><a href="/ideas-advice/design-your-space/dining-kitchen/dining-kitchen-table-finder">Dining Table Finder</a></li>
					<li><a href="/ideas-advice/explore-materials/fabric-guide">Fabric Guide</a></li>
					<li><a href="/images/pdf/rnb_frame_wall.pdf">Frame Wall Guide</a></li>
					<li><a href="/ideas-advice/explore-materials/leather-guide">Leather Guide</a></li>
					<li><a href="/ideas-advice/design-your-space/bedroom/how-to-choose-a-mattress">Mattress Comparison Guide</a></li>
					<li><a href="/ideas-advice/design-your-space/office/office-chair-comparison-guide">Office Chair Comparison Guide</a></li>
					<li><a href="/ideas-advice/product-care">Product Care</a></li>
					<li><a href="/ideas-advice/design-your-space/ideas-for-any-room/how-to-choose-a-rug-size">Rug Size Guide</a></li>
					<li><a href="/ideas-advice/design-your-space/living/sofa-and-sectional-comfort-guide">Sofa Comfort Guide</a></li>
					<li><a href="/images/pdf/RNB_Top_Base.pdf">Top & Base Guide</a></li>
				</ul>
			</section>
			<section className={legacyStyles.section}>
				<h3 className="tw-heading-6 tw-uppercase">Resources</h3>
				<ul>
					<li><a href="/customer-service/international-shipping">AK/HI/International Shipping</a></li>
					<li><a href="/business/register" data-qa="business-registration">Business Program Registration</a></li>
					<li><a href="/customer-service/certificate-of-insurance">Certificate of Insurance</a></li>
					<li><a href={giftCardsBalanceUrl} rel="noreferrer" target="_blank">Check Gift Card Balance</a></li>
					<li><a href="/customer-service/delivery/">Delivery Information</a></li>
					<li><a href="https://roomandboard.bookingbug.com/saml_init?id=gAt2zUO6Cvj2s_QPDuhntQ&admin=true" target="_blank" rel="noopener noreferrer">Design Appointments</a></li>
					<li><a href="https://roomandboard.sharepoint.com/sites/CustomerCare/SitePages/Support-Portal.aspx" target="_blank" rel="noopener noreferrer">Support Portal</a></li>
					<li><a href="https://roomandboard.sharepoint.com/sites/RetailDeliveryResources/SitePages/Working-with-Designers-and-Business-Customers.aspx" target="_blank" rel="noopener noreferrer">Working with Business Customers</a></li>
				</ul>
			</section>
		</div>
	);
};

const QuickLinksNew = () => {
	return (
		<div className={styles.sideMenuInside}>
			<div className={`${styles['side-menu-contents']} ${styles['side-menu-contents-quicklinks']}`} data-qa="side-menu-contents">
				<h2 className="tw-heading-4 tw-pb-4">Quick Links</h2>
				<section className={styles.section}>
					<h3 className="tw-heading-5">Samples & Swatches</h3>
					<ul>
						<li><a href="/ideas-advice/explore-materials/fabrics/indoor">Fabric & Leather Swatches</a></li>
						<li><a href="/catalog/dining-and-kitchen/material-samples">Paid Material Samples</a></li>
					</ul>
				</section>
				<section className={styles.section}>
					<h3 className="tw-heading-5">Guides & Care</h3>
					<ul>
						<li><a href="/ideas-advice/design-your-space/dining-kitchen/dining-kitchen-table-finder">Dining Table Finder</a></li>
						<li><a href="/ideas-advice/explore-materials/fabric-guide">Fabric Guide</a></li>
						<li><a href="/images/pdf/rnb_frame_wall.pdf">Frame Wall Guide</a></li>
						<li><a href="/ideas-advice/explore-materials/leather-guide">Leather Guide</a></li>
						<li><a href="/ideas-advice/design-your-space/bedroom/how-to-choose-a-mattress">Mattress Comparison Guide</a></li>
						<li><a href="/ideas-advice/design-your-space/office/office-chair-comparison-guide">Office Chair Comparison Guide</a></li>
						<li><a href="/ideas-advice/product-care">Product Care</a></li>
						<li><a href="/ideas-advice/design-your-space/ideas-for-any-room/how-to-choose-a-rug-size">Rug Size Guide</a></li>
						<li><a href="/ideas-advice/design-your-space/living/sofa-and-sectional-comfort-guide">Sofa Comfort Guide</a></li>
						<li><a href="/images/pdf/RNB_Top_Base.pdf">Top & Base Guide</a></li>
					</ul>
				</section>
				<section className={styles.section}>
					<h3 className="tw-heading-5">Resources</h3>
					<ul>
						<li><a href="/customer-service/international-shipping">AK/HI/International Shipping</a></li>
						<li><a href="/business/register" data-qa="business-registration">Business Program Registration</a></li>
						<li><a href="/customer-service/certificate-of-insurance">Certificate of Insurance</a></li>
						<li><a href={giftCardsBalanceUrl} rel="noreferrer" target="_blank">Check Gift Card Balance</a></li>
						<li><a href="/customer-service/delivery/">Delivery Information</a></li>
						<li><a href="https://roomandboard.bookingbug.com/saml_init?id=gAt2zUO6Cvj2s_QPDuhntQ&admin=true" target="_blank" rel="noopener noreferrer">Design Appointments</a></li>
						<li><a href="https://roomandboard.sharepoint.com/sites/CustomerCare/SitePages/Support-Portal.aspx" target="_blank" rel="noopener noreferrer">Support Portal</a></li>
						<li><a href="https://roomandboard.sharepoint.com/sites/Retail/SitePages/Virtual-Fit-Call-Toolkit-Site.aspx " target="_blank" rel="noopener noreferrer" data-qa="virtual-fit-appointment-link">Virtual Fit Appointment</a></li>
						<li><a href="https://roomandboard.sharepoint.com/sites/RetailDeliveryResources/SitePages/Working-with-Designers-and-Business-Customers.aspx" target="_blank" rel="noopener noreferrer">Working with Business Customers</a></li>
					</ul>
				</section>
			</div>
		</div>
	);
};

export const QuickLinks = () => {
	const { featureTogglesModel } = useGlobalContext();

	if (featureTogglesModel.isOn('ENGAGE_TOOLBAR_REDESIGN')) {
		return <QuickLinksNew />;
	}
	return <LegacyQuickLinks />;
};
