import type { AxiosError } from 'axios';

import { isString } from '~/util/isString';

export class PromiseError {
	error: AxiosError;

	fallbackErrorMessage = 'An error has occurred.';

	get debugMessage() {
		if (isString(this.error)) {
			return this.error;
		}
		if (this.error === null) {
			return '';
		}
		const {
			response: {
				data: {
					errors: [
						{
							debugMessage = '',
						} = {},
					] = [],
				} = {},
			} = {} as any,
		} = this.error;

		return debugMessage;
	}

	get errorKey() {
		if (isString(this.error)) {
			return this.error;
		}
		if (this.error === null) {
			return '';
		}
		const {
			error: {
				response: {
					data: {
						errors: [
							{
								errorKey = '',
							} = {},
						] = [],
					} = {} as any,
				} = {},
			} = {},
		} = this;

		return errorKey.trim();
	}

	get errorMessage() {
		if (isString(this.error)) {
			return this.error;
		}
		const {
			error: {
				response: {
					data: {
						errors: [
							{
								errorMessage = '',
							} = {},
						] = [],
					} = {} as any,
				} = {},
				message,
			} = {},
		} = this;

		return errorMessage || message || this.fallbackErrorMessage;
	}

	get errorMessageObj() {
		return {
			messageKey: this.errorKey,
			messageValues: this.errorValues as object,
		};
	}

	get errorValues() {
		if (isString(this.error)) {
			return this.error;
		}
		if (this.error === null) {
			return '';
		}
		const {
			error: {
				response: {
					data: {
						errors: [
							{
								errorValues = {},
							} = {},
						] = [],
					} = {} as any,
				} = {},
			} = {},
		} = this;

		return errorValues;
	}

	get data() {
		const {
			error: {
				response: {
					data,
				} = {},
			} = {},
		} = this;

		return data;
	}

	get isFallbackErrorMessage() {
		return this.errorMessage === this.fallbackErrorMessage;
	}

	get status() {
		const {
			error: {
				response: {
					status,
				} = {},
			} = {},
		} = this;

		return status;
	}

	constructor(error: AxiosError) {
		if (!error) {
			throw new Error('No error found.');
		}
		this.error = error;
	}
}
