import React from 'react';
import { observer } from 'mobx-react';

import { EmailField } from '~/util/formz/Components/fields/EmailField';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const EmailLookup = observer(({ store = {} }) => {
	const { featureTogglesModel } = useGlobalContext();
	const submitText = featureTogglesModel.isOn('ENGAGE_TOOLBAR_REDESIGN') ? 'Search' : 'Submit';
	const {
		form: {
			reactProps = {},
			fields: {
				email: emailField = {},
			} = {},
		} = {},
	} = store;

	const {
		key,
		...formReactProps
	} = reactProps;

	return (
		<form key={key} {...formReactProps}>
			<div className="form-layout">
				<EmailField field={emailField} />
			</div>
			<input type="submit" className="ButtonTertiary" value={submitText} data-qa="customer-search-button" />
		</form>
	);
});
