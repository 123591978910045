import { action, makeObservable } from 'mobx';
import axios, { type AxiosResponse } from 'axios';

import { type OpenDeliverySummaryDto } from '~/engage/toolbar/open-delivery-summary/Models/OpenDeliverySummary.model';
import { type MagicOverlayStore } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { OpenDeliverySummaryModel } from '~/engage/toolbar/open-delivery-summary/Models/OpenDeliverySummary.model';
import { MagicOverlayStoreFactory } from '~/components/magic-overlay/Stores/MagicOverlay.store';

export class OpenDeliverySummaryStore {
	magicOverlayStore!: MagicOverlayStore | null;

	model!: OpenDeliverySummaryModel;

	constructor() {
		makeObservable(this, {
			fetchOpenDeliveryGroups: action.bound,
			init: action.bound,
			onFetchFailure: action.bound,
			onFetchSuccess: action.bound,
			refreshOpenDeliveryGroups: action.bound,
		});
	}

	fetchOpenDeliveryGroups() {
		const {
			openDeliveryGroupsLink = '',
		} = this.model;

		if (!openDeliveryGroupsLink) {
			return Promise.reject('openDeliveryGroupsLink not found.');
		}
		return axios.get(openDeliveryGroupsLink);
	}

	async fetchOpenDeliveryGroupsStatus() {
		if (!this.model.openDeliveryGroupsStatusLink) {
			return;
		}
		try {
			const response = await axios.get(this.model.openDeliveryGroupsStatusLink);

			this.model.setShowTruckIcon(Boolean(response.data.hasOpenDeliveryGroups));
		} catch (error) {
			console.error(error);
		}
	}

	async init() {
		try {
			const promise = this.fetchOpenDeliveryGroups();

			this.magicOverlayStore?.startLoading(promise);
			const response = await promise;

			this.onFetchSuccess(response);
		} catch (error) {
			this.onFetchFailure(error);
		}
	}

	async initStatus() {
		await this.fetchOpenDeliveryGroupsStatus();
	}

	onFetchFailure(error: unknown) {
		const message = error !== null && typeof error === 'object' && 'message' in error ? error.message : '';

		return new Error(`Could not fetch open delivery groups. ${message}`);
	}

	onFetchSuccess(resp: AxiosResponse<OpenDeliverySummaryDto>) {
		const { data } = resp;

		if (data) {
			this.model.data = data;
		}
		return resp;
	}

	refreshOpenDeliveryGroups() {
		const {
			model: {
				refreshLink = '',
			} = {},
		} = this;

		if (!refreshLink) {
			return Promise.reject('refreshLink not found.');
		}
		return axios.get(refreshLink).then(this.onFetchSuccess).catch(this.onFetchFailure);
	}
}

export const OpenDeliverySummaryStoreFactory = {
	create(openDeliveryGroupsLink?: string, openDeliveryGroupsStatusLink?: string) {
		const store = new OpenDeliverySummaryStore();

		store.model = new OpenDeliverySummaryModel(openDeliveryGroupsLink, openDeliveryGroupsStatusLink);

		store.magicOverlayStore = MagicOverlayStoreFactory.create({
			message: false,
		});

		return store;
	},
};
