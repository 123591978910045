import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { CartSearchForm } from '~/engage/cart-search/Components/CartSearchForm';
import { CartSearchStoreFactory } from '~/engage/cart-search/Stores/CartSearch.store';
import { useToolbarStores } from '~/engage/toolbar/Contexts/Toolbar.context';
import { useGlobalContext } from '~/global/Contexts/Global.context';

// RNB.Engage.CartSearch.Renderer.cartSearch
export const CartSearch = observer(() => {
	const { toolbarStore } = useToolbarStores();
	const { globalDynamicModel, featureTogglesModel } = useGlobalContext();
	const [store] = useState(CartSearchStoreFactory.create(toolbarStore, globalDynamicModel, featureTogglesModel));

	return <CartSearchForm form={store.form} />;
});
