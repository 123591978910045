import React, { ReactNode } from 'react';

import { ERROR_KEY } from '~/util/messaging/promise-error/error.constants';
import { DefaultPromiseErrorMessage } from '~/util/messaging/promise-error/DefaultPromiseErrorMessage';
import { PromiseErrorMessages } from '~/util/messaging/promise-error/PromiseErrorMessages';

export interface ErrorMessage {
	messageKey?: ERROR_KEY
	messageValues?: any
	errorKey?: ERROR_KEY
	errorValues?: any
}
interface Props {
	childProps?: object,
	children?: ReactNode
	classNames?: string
	errorMessage?: ErrorMessage
	inline?: boolean
	readOnly?: boolean
}
export const PromiseErrorMessaging = (props: Props) => {
	const {
		classNames = 'promise-error-messages',
		errorMessage,
		inline = false,
		readOnly = false,
		childProps,
		children
	} = props;

	if (children && React.Children.count(children)) {
		return (
			<div className={classNames}>{children}</div>
		);
	}
	if (!errorMessage) {
		return null;
	}

	let {
		messageKey,
		messageValues,
	} = errorMessage;

	const {
		errorKey,
		errorValues,
	} = errorMessage;

	if (!messageKey) {
		messageKey = errorKey;
		messageValues = errorValues;
	}
	const MessageComponent = messageKey
		? PromiseErrorMessages[messageKey] || DefaultPromiseErrorMessage
		: DefaultPromiseErrorMessage;

	if (!MessageComponent) {
		return null;
	}
	return (
		<div className={classNames}>
			<MessageComponent
				// TODO (aboyer) Need to refactor typing.
				// @ts-ignore
				values={{
					...childProps,
					...messageValues,
				}}
				messageKey={messageKey}
				readOnly={readOnly}
				inline={inline}
			/>
		</div>
	);
};
