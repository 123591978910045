'use client';

import React from 'react';
import { observer } from 'mobx-react';

export const Error = observer((props) => {
	return (
		<div>
			<p>System failed. Please try again or cancel.</p>
			<div className="form-actions ButtonGroup">
				<button className="Button tw-uppercase" onClick={props.tryAgainHandler} type="button">Try Again</button>
				<button className="ButtonAnchor" onClick={(event) => {
					event.preventDefault();
					props.cancelHandler();
				}} type="button">Cancel</button>
			</div>
		</div>
	);
});
