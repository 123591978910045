enum ERROR_KEY {
	DEFAULT = 'default',
	AFFIRM_ACTION_REQUIRED = 'affirmActionRequired',
	ACCOUNT_EXISTS_CHECKOUT_ERROR = 'accountExistsCheckoutError',
	ACCOUNT_EXISTS_ERROR = 'accountExistsError',
	AVS_ATTEMPT_FAILED = 'AVS_ATTEMPT_FAILED',
	BLOCKED_CUSTOMER_ERROR = 'blockedCustomerError',
	DELIVERY_ADDRESS_RESTRICTED_BY_INVENTORY = 'deliveryAddressRestrictedByInventory',
	DUPLICATE_GIFT_CARD_PAYMENT = 'duplicateGiftCardPayment',
	DUPLICATE_QUOTE_CONTACT = 'duplicateQuoteContactError',
	DUPLICATE_CONTACT = 'duplicateContactError',
	FINANCE_ACTION_REQUIRED = 'financeActionRequired',
	EMAIL_OR_PASSWORD_NOT_FOUND = 'emailOrPasswordNotFoundError',
	FINANCIAL_TRANSACTION_CANCELLED = 'financialTransactionCancelled',
	FINANCIAL_TRANSACTION_CONTACTLESS_REMOVED_TOO_SOON = 'financialTransactionContactlessRemovedTooSoon',
	FINANCIAL_TRANSACTION_DECLINED = 'financialTransactionDeclined',
	FINANCIAL_TRANSACTION_ERROR = 'financialTransactionError',
	FINANCIAL_TRANSACTION_INVALID_CARD_INFORMATION = 'financialTransactionInvalidCardInformation',
	FINANCIAL_TRANSACTION_SAP_CONNECTION_ERROR = 'financialTransactionSapConnectionError',
	FINANCIAL_TRANSACTION_TERMINAL_BUSY = 'financialTransactionTerminalBusy',
	FINANCIAL_TRANSACTION_TIMED_OUT = 'financialTransactionTimedOut',
	FINANCIAL_TRANSACTION_WITH_TOKEN_DECLINED = 'financialTransactionWithTokenDeclined',
	GIFT_CARD_ALREADY_REDEEMED = 'giftCardAlreadyRedeemed',
	GIFT_CARD_INVALID_PRODUCT_ERROR = 'giftCardInvalidProductError',
	GIFT_CARD_TEMPORARILY_BLOCKED = 'giftCardTemporarilyBlocked',
	GIFT_CARD_VENDOR_TIMEOUT = 'giftCardVendorTimeout',
	HOT_PAYMENTS_ERROR = 'hotPaymentsError',
	HOT_REFUNDS_ERROR = 'hotRefundsError',
	INSUFFICIENT_FUNDS_ON_GIFT_CARD = 'insufficientFundsOnGiftCard',
	INSUFFICIENT_PAYMENT_ERROR = 'insufficientPaymentError',
	INVALID_EMAIL_ERROR = 'invalidEmailError',
	// Physical gift cards
	INVALID_GIFT_CARD_NUMBER = 'invalidGiftCardNumber',
	INVALID_GIFT_CARD_NUMBER_OR_PIN = 'invalidGiftCardNumberOrPin',
	LINE_ITEM_SIDEMARKING_EXISTS_ERROR = 'lineItemSidemarkingExistsError',
	MAX_AVS_ATTEMPTS_REACHED = 'MAX_AVS_ATTEMPTS_REACHED',
	// Version mismatch.
	OPTIMISTIC_LOCK_FAILURE = 'optimisticLockFailure',
	ORDER_CONFIRMATION_FAILURE = 'orderConfirmationFailure',
	ORDER_EDIT_PATCH_FAILED_NEW_ORDER_CREATED_ERROR_KEY = 'orderEditPatchFailedNewOrderCreatedErrorKey',
	PAYMENTS_EXCEED_AMOUNT_DUE = 'paymentsExceedAmountDue',
	PLACED_ORDER_NOT_FOUND = 'placedOrderNotFound',
	REFUND_REQUIRES_FINANCE = 'refundRequiresFinance',
	// Web only.
	RESOURCE_LOCKED = 'resourceLocked',
	// Engage only.
	RESOURCE_LOCKED_BY_USER = 'resourceLockedByUser',
	ROUTING_ERROR = 'routingError',
	SALESFORCE_INVALID_EMAIL = 'salesforceInvalidEmail',
	SAP_CONNECTION_ERROR = 'sapConnectionError',
	SHAREPOINT_CONNECTION_ERROR = 'sharepointConnectionError',
	SHAREPOINT_AUTH_ERROR = 'authenticationFailed',
	USERNAME_OR_PASSWORD = 'usernameOrPassword',
	USERNAME_OR_PASSWORD_NOT_FOUND = 'usernameOrPasswordNotFoundError',
	USERNAME_UNIQUE_CONSTRAINT_ERROR = 'username',
	WEB_CREDIT_CARD_DECLINED_ERROR = 'webCreditCardDeclinedError',
	WEB_CREDIT_CARD_EXPIRED_ERROR = 'webCreditCardExpiredError',
}

const isValidErrorKey = (
	subject: ERROR_KEY | string | undefined,
): subject is ERROR_KEY => {
	return !!subject && Object.keys(ERROR_KEY).includes(subject);
};

export {
	ERROR_KEY,
	isValidErrorKey,
};
