'use client';

import React from 'react';
import { observer } from 'mobx-react';

import { EmailField } from '~/util/formz/Components/fields/EmailField';
import { Field } from '~/util/formz/Components/fields/Field';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';

export const Form = observer((props) => {
	const {
		createAccountStore: store,
		createAccountStore: {
			createAccountFormStore: formStore,
			createAccountFormStore: {
				form: {
					reactProps: {
						key: formKey = 'formKey',
						...formReactProps
					} = {},
				} = {},
			} = {},
		},
	} = props;

	return (
		<form key={formKey} {...formReactProps}>
			{
				<>
					<EmailField field={formStore.form.fields.username} />
					{
						formStore.promiseError &&
						<div className="tw-mb-4">
							<PromiseErrorMessaging
								errorMessage={formStore.promiseError.errorMessageObj}
								childProps={{
									email: formStore.form.model.username,
								}}
								inline
							/>
						</div>
					}
				</>
			}
			<Field field={formStore.form.fields.zipCode} />
			<Field field={formStore.form.fields.optIn} />
			<div className="form-actions ButtonGroup">
				<input
					type="submit"
					className="Button"
					data-qa="create-account-modal-submit-button"
					defaultValue="Submit"
				/>
				<button className="ButtonAnchor" onClick={(event) => {
					event.preventDefault(); store.cancelHandler();
				}}>Cancel</button>
			</div>
		</form>
	);
});
