import {
	model, Model, prop, idProp,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';

export type DesignAssociateFollowing = 'SELF' | 'ANOTHER' | null;

@model(`${modelNamespace.OPPORTUNITIES}/OpportunitiesToolbarModel`)
export class OpportunitiesToolbarModel extends Model({
	id: idProp,
	createNewOpportunityLink: prop<string | undefined>(),
	employeeName: prop<string | undefined>(),
	dateCreated: prop<string | undefined>(),
	isMyOpportunity: prop<boolean>(),
	reassignOpportunityLink: prop<string | undefined>(),
	removeOpportunityLink: prop<string | undefined>(),
	viewOpportunitiesLink: prop<string | undefined>(),
}) {
	get canReassignOpportunity() {
		return !this.isMyOpportunity && this.reassignOpportunityLink !== undefined;
	}

	// @deprecated ENGAGE_TOOLBAR_REDESIGN
	get showAddToMyOpportunitiesButton() {
		return !this.isMyOpportunity && Boolean(this.createNewOpportunityLink);
	}

	get designAssociateFollowing(): DesignAssociateFollowing {
		if (this.isMyOpportunity) {
			return 'SELF';
		}
		if (this.canReassignOpportunity) {
			return 'ANOTHER';
		}
		return null;
	}
}
