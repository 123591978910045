import React from 'react';
import { observer } from 'mobx-react';

import { QuickSearch } from '~/engage/shop-search/quick-search/Components/QuickSearch';
import { AdvancedSearch } from '~/engage/shop-search/advanced-search/Components/AdvancedSearch';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useToolbarStores } from '~/engage/toolbar/Contexts/Toolbar.context';
import { SellGiftCardStoreFactory } from '~/engage/sell-gift-card/Stores/SellGiftCard.store';

import shopStyles from '~/engage/toolbar/side-menu/Components/shop-side-menu.legacy.module.scss';
import legacyMenuStyles from '~/engage/toolbar/side-menu/Components/side-menu.legacy.module.scss';
import menuStyles from '~/engage/toolbar/side-menu/Components/side-menu.module.scss';

const ShopLegacy = observer(() => {
	const {
		featureTogglesModel,
		globalDynamicModel: {
			addGiftCardToCartLink = '',
		} = {},
		globalDynamicStore = {},
		magicModal,
	} = useGlobalContext();
	const { toolbarActions } = useToolbarStores();

	return (
		<div className={`${legacyMenuStyles['side-menu-contents']} ${shopStyles['side-menu-contents-shop']}`} data-qa="side-menu-contents-shop">
			<div className={legacyMenuStyles.section}>
				<h3 className="tw-heading-6 tw-uppercase">Quick Add By Item Number</h3>
				<p>Includes non-web items; excludes custom and configurable items.</p>
				<QuickSearch />
			</div>
			<div className={legacyMenuStyles.section}>
				<h3 className="tw-heading-6 tw-uppercase">Quick Add By Item Name</h3>
				<p>Includes non-web items; excludes custom and configurable items.</p>
				<AdvancedSearch />
			</div>
			<div className={legacyMenuStyles.section}>
				<h3 className="tw-heading-6 tw-uppercase">More Tools</h3>
				<ul>
					{
						Boolean(addGiftCardToCartLink) &&
						<li>
							<button
								type="button"
								data-qa="sell-gift-card-button"
								className="ButtonAnchor tw-text-base"
								onClick={() => {
									toolbarActions.closeMenu();
									SellGiftCardStoreFactory.create(
										addGiftCardToCartLink,
										featureTogglesModel,
										magicModal,
										globalDynamicStore,
									);
								}}
							>
								Sell a Gift Card
							</button>
						</li>
					}
					<li>
						<a
							href="https://roomandboard.sharepoint.com/:x:/r/sites/Retail/Supporting%20Assortment%20Resources/Stock%20Furniture%20Price%20Change%20List%20as%20of%2001.04.2023%20-%20Shared%20with%20Retail%2012.16.22.xlsx?d=wba5b9aecea5e43ba8d54914b27ac201c&csf=1&web=1&e=AQJnlh"
							target="_blank"
						>
							Retail Price Change List (Excel)
						</a>
					</li>
					{
						featureTogglesModel.isOn('ENGAGE_QUICK_LINK_ELY') &&
						<>
							<li>
								<a
									href="/catalog/living/every-last-yard-event"
									target="_blank"
								>
									Every Last Yard Products
								</a>
							</li>
							<li>
								<a
									href="https://roomandboard.sharepoint.com/:x:/t/Azure-Product/EaDjyHm4lR9OmDpbKcv97y8BCV64nsT3kX5FYl8p83wvsQ?e=T5AwYV"
									target="_blank"
								>
									Every Last Yard Price Sheet (Excel)
								</a>
							</li>
						</>
					}
				</ul>
			</div>
		</div>
	);
});

const ShopNew = observer(() => {
	const {
		featureTogglesModel,
		globalDynamicModel: {
			addGiftCardToCartLink = '',
		} = {},
		globalDynamicStore = {},
		magicModal,
	} = useGlobalContext();
	const { toolbarActions } = useToolbarStores();

	return (
		<div className={menuStyles.sideMenuInside}>
			<div
				className={`${menuStyles['side-menu-contents']} ${shopStyles['side-menu-contents-shop']}`}
				data-qa="side-menu-contents-shop"
			>
				<h2 className="tw-heading-4 tw-pb-4">Shop</h2>
				<div className={menuStyles.section}>
					<h3 className="tw-heading-5">Quick Add By Item Number</h3>
					<p>Includes non-web items; excludes custom and configurable items.</p>
					<QuickSearch />
				</div>
				<div className={menuStyles.section}>
					<h3 className="tw-heading-5">Quick Add By Item Name</h3>
					<p>Includes non-web items; excludes custom and configurable items.</p>
					<AdvancedSearch />
				</div>
				<div className={menuStyles.section}>
					<h3 className="tw-heading-5">More Tools</h3>
					<ul>
						{
							Boolean(addGiftCardToCartLink) &&
							<li>
								<button
									type="button"
									data-qa="sell-gift-card-button"
									className="ButtonAnchor tw-text-base tw-pt-0"
									onClick={() => {
										toolbarActions.closeMenu();
										SellGiftCardStoreFactory.create(
											addGiftCardToCartLink,
											featureTogglesModel,
											magicModal,
											globalDynamicStore,
										);
									}}
								>
									Sell a Gift Card
								</button>
							</li>
						}
						<li>
							<a
								href="https://roomandboard.sharepoint.com/:x:/r/sites/Retail/Supporting%20Assortment%20Resources/Stock%20Furniture%20Price%20Change%20List%20as%20of%2001.04.2023%20-%20Shared%20with%20Retail%2012.16.22.xlsx?d=wba5b9aecea5e43ba8d54914b27ac201c&csf=1&web=1&e=AQJnlh"
								target="_blank"
								rel="noopener noreferrer"
							>
								Retail Price Change List (Excel)
							</a>
						</li>
						{
							featureTogglesModel.isOn('ENGAGE_QUICK_LINK_ELY') &&
							<>
								<li>
									<a
										href="/catalog/living/every-last-yard-event"
										target="_blank"
									>
										Every Last Yard Products
									</a>
								</li>
								<li>
									<a
										href="https://roomandboard.sharepoint.com/:x:/t/Azure-Product/EaDjyHm4lR9OmDpbKcv97y8BCV64nsT3kX5FYl8p83wvsQ?e=T5AwYV"
										target="_blank"
									>
										Every Last Yard Price Sheet (Excel)
									</a>
								</li>
							</>
						}
					</ul>
				</div>
			</div>
		</div>
	);
});

export const Shop = () => {
	const { featureTogglesModel } = useGlobalContext();

	if (featureTogglesModel.isOn('ENGAGE_TOOLBAR_REDESIGN')) {
		return <ShopNew />;
	}
	return <ShopLegacy />;
};
