import React from 'react';
import { observer } from 'mobx-react';

import { sitePath } from '~/global/global.constants';
import { Button } from '~/components/Buttons/Components/Button';

export const FileUpload = observer(({ decode }: { decode: (src: string) => void }) => {
	const buttons = [
		{
			barcodeSrc: '/img/barcodes/barcode-sample-blank.png',
			label: 'Blank',
		},
		{
			barcodeSrc: '/img/barcodes/barcode-sample-invalid.png',
			label: 'Invalid',
		},
		{
			barcodeSrc: '/img/barcodes/barcode-sample-long.png',
			label: 'Too long',
		},
		{
			barcodeSrc: '/img/barcodes/barcode-sample-short.png',
			label: 'Too short',
		},
		{
			barcodeSrc: '/img/barcodes/barcode-sample-valid.png',
			label: 'Valid (19-digit)',
		},
		{
			barcodeSrc: '/img/barcodes/barcode-sample-valid-30.png',
			label: 'Valid (30-digit)',
		},
	];

	return (
		<div className="GiftCardScanner-fileUploadContainer">
			<div className="GiftCardScanner-fileUploadLabel">
				Pick a test barcode image to scan or upload your own
			</div>
			<div className="GiftCardScanner-fileUploadOptions tw-space-y-4">
				{
					buttons.map(({ barcodeSrc, label }) => {
						return (
							<div key={label}>
								<Button
									type="button"
									onClick={() => {
										decode(sitePath + barcodeSrc);
									}}
								>
									{label}
								</Button>
							</div>
						);
					})
				}
				<input
					className="ButtonExtended ButtonExtended--navy01"
					type="file"
					onChange={(event) => {
						const {
							target: {
								files,
							} = {},
						} = event;

						if (!files) {
							return;
						}
						decode(URL.createObjectURL(files[0]));
					}}
				/>
			</div>
		</div>
	);
});
