import {
	action, computed, observable, toJS, makeObservable
} from 'mobx';
import React from 'react';
import axios from 'axios';

import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { CreateAccountFormStore } from '~/engage/toolbar/create-account/Stores/CreateAccountForm.store';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { Form } from '~/engage/toolbar/create-account/Components/Form';
import { Success } from '~/engage/toolbar/create-account/Components/Success';
import { Error } from '~/engage/toolbar/create-account/Components/Error';

class CreateAccountStore {
	constructor(magicModal, globalDynamicStore, options = {}) {
		makeObservable(this, {
			createAccountFormStore: observable,
			createAccountFormOutput: computed,
			submitHandler: action.bound,
			cancelHandler: action.bound,
			updateDeliveryZipCode: action.bound,
			fetchSapCustomerFullName: action.bound,
		});

		this.customerName = null;
		this.magicModal = magicModal;
		this.globalDynamicStore = globalDynamicStore;
		this.config = {
			formDefaults: options?.formDefaults,
			modalOptions: options?.modalOptions,
			onCreateAccountSuccess: options?.onCreateAccountSuccess,
			onCreateAccountSuccessView: options?.onCreateAccountSuccessView || 'success',
		};

		this.viewState = new ViewStateStore(this, {
			loading: {
				component: () => {
					return (
						<div style={{ minHeight: '200px' }}>
							<LoadingSpinner isLoading />
						</div>
					);
				},
			},
			form: {
				component: Form,
				onEnter: (viewState, store) => {
					this.magicModal.alterModal({
						title: this.customerName ? `Set Up Online Access for ${this.customerName.replace(/(.{5})/g, '$1\u00AD')}` : 'Set Up Online Access',
					});
					return {
						createAccountStore: store
					};
				}
			},
			success: {
				component: Success,
				onEnter: (viewState, store) => {
					this.magicModal.alterModal({
						title: 'Success! Access Granted'
					});
					return {
						cancelHandler: store.cancelHandler
					};
				}
			},
			error: {
				component: Error,
				onEnter: (viewState, store, parameters) => {
					this.magicModal.alterModal({
						title: 'Unable to Process'
					});
					return {
						tryAgainHandler: () => {
							console.log(viewState, store, parameters);
							store.viewState.goTo('form');
						},
						cancelHandler: store.cancelHandler
					};
				}
			}
		});
		this.createAccountFormStore = new CreateAccountFormStore(this, this.submitHandler, this.config.formDefaults);
		this.createAccountFormStore.create();

		this.fetchSapCustomerFullName().then(() => {
			this.viewState.goTo('form');
		});
	}

	createAccountFormStore = {};

	get createAccountFormOutput() {
		return this.createAccountFormStore.model;
	}

	fetchSapCustomerFullName() {
		const link = this.globalDynamicStore.model.createAccountLink;

		const promise = axios.get(link).then((response) => {
			this.customerName = response.data.sapCustomerFullName;
		}).catch((error) => {
			console.error('error getting customers sapCustomerFullName:', error);
		});

		return promise;
	}

	submitHandler(form) {
		const link = this.globalDynamicStore.model.createAccountLink;
		const payload = toJS(this.createAccountFormOutput);

		form.plugins.formValidator.validateForm();
		if (form.plugins.formValidator.hasErrors) {
			return null;
		}
		this.viewState.goTo('loading');
		this.updateDeliveryZipCode(payload.zipCode);
		return axios.post(link, payload);
	}

	cancelHandler() {
		this.magicModal.closeModal();
	}

	updateDeliveryZipCode(zipCode) {
		this.globalDynamicStore.transZoneStore.setTransZone(zipCode);
	}
}

export { CreateAccountStore };
