import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import barcodeBtnStyles from '#/engage/BarcodeButton.module.scss';

import styles from '~/engage/sell-gift-card/Components/SellGiftCard.module.scss';
import { Field } from '~/util/formz/Components/fields/Field';
import { UsCurrency } from '~/util/formz/Components/plugins/UsCurrency';
import { autoFocusHelper } from '~/util/autoFocusHelper';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { ErrorMessage } from '~/engage/sell-gift-card/Components/ErrorMessage';

export const SellGiftCardView = observer(({
	barcodeButtonClickHandler, model = {}, tryAgainClickHandler,
}) => {
	const {
		error,
		form: {
			fields = {},
			plugins: {
				formValidator: {
					hasErrors = true,
				} = {},
			} = {},
			reactProps = {},
		} = {},
		showBarcodeButton = true,
	} = model;
	const { magicModal } = useGlobalContext();
	const barcodeButton = showBarcodeButton
		? (
			<div className={styles['SellGiftCard-barcodeButton']}>
				<button
					type="button"
					className={`${barcodeBtnStyles['BarcodeButton']} tw-mr-1`}
					data-qa="barcode-button"
					onClick={barcodeButtonClickHandler}
					onMouseDown={event => event.preventDefault()}
					ref={autoFocusHelper}
				>
					<img
						src="/img/ico/barcode.svg"
						alt="Scan gift card"
						className={barcodeBtnStyles['BarcodeButton-icon']}
						width="40"
						height="27"
					/>
				</button>
			</div>
		)
		:
		null;

	return (
		<form {...reactProps}>
			<div className={cn({
				'tw-opacity-50': !showBarcodeButton,
			})}>
				<Field field={fields.cardNumber} afterLabel={barcodeButton} />
			</div>
			<UsCurrency
				field={fields.amount}
				beforeControl={
					<div className="tw-text-gray-300 tw-mb-1">
						Gift cards are available in full dollar amounts from $25–$2,000.
					</div>
				}
			/>
			<div className="ButtonGroup">
				<button
					data-qa="sell-gift-card-add-to-cart-button"
					className="Button tw-whitespace-nowrap"
					disabled={hasErrors}
				>
					Add to Cart
				</button>
				<button
					data-qa="sell-gift-card-cancel-button"
					className="ButtonAnchor"
					type="button"
					onMouseDown={(event) => {
						event.stopPropagation();
						event.preventDefault();
					}}
					onClick={(event) => {
						event.preventDefault();
						magicModal.closeModal();
					}}
				>Cancel</button>
			</div>
			<ErrorMessage error={error} tryAgainClickHandler={tryAgainClickHandler} />
		</form>
	);
});
